







































import {
  Component, Vue, Watch,
} from 'vue-property-decorator';

import FeedbackUser from '@/components/FeedbackUser/index.vue';

import MenuPlan from '../MenuPlan/MenuPlan.vue';
import CardPlan from '../CardPlan/CardPlan.vue';
import CardPlanLoading from '../CardPlanLoading/CardPlanLoading.vue';

import PlansService from '@/services/Plans/PlansService';

import { Plan, Discount } from '../../interfaces/IShopping';

import { TYPE_DISCOUNT } from '../../constants/index';

import { DEFAULT_INTERVAL_COUNT } from './constants';

import { getPriceDiscount } from '@/share/Util/Price/index';

@Component({
  components: {
    MenuPlan,
    CardPlan,
    CardPlanLoading,
    FeedbackUser,
  },
})
export default class ContainerPlans extends Vue {
  private isLoadingPlans = true;

  private intervalCount = DEFAULT_INTERVAL_COUNT;
  private TYPE_DISCOUNT = TYPE_DISCOUNT;

  private plans!: Array<Plan>;
  private currentPlans = [] as Array<Plan>;

  private PlansService = new PlansService();

  created() {
    this.getPlans();
  }

  get activePlanTypes() {
    return this.$store.getters.activePlanTypes;
  }

  @Watch('intervalCount')
  async getPlans() {
    try {
      this.isLoadingPlans = true;

      const response = await this.PlansService.getPlans({});

      if (response && response?.plans) {
        this.plans = response.plans;

        this.setCurrentPlans();
      }
    } catch (error) {
      this.$store.dispatch('Toast/setToast', {
        text: 'Erro ao carregar os planos.',
        status: 'error',
      });

      console.error(error);
    } finally {
      this.isLoadingPlans = false;
    }
  }

  handlePriceDiscount(plan: Plan, type: string) {
    const totalPrice = plan.planPrice;

    const foundDiscountPrice = plan.discounts.find((discount: Discount) => discount.code === type);

    if (!foundDiscountPrice) return totalPrice;

    let price = 0;

    if (foundDiscountPrice.percent) {
      price = (totalPrice * (foundDiscountPrice.percent / 100)) < foundDiscountPrice.max_value
        ? totalPrice - foundDiscountPrice.max_value
        : totalPrice - totalPrice * (foundDiscountPrice.percent / 100);
    }

    if (foundDiscountPrice.value) {
      price = totalPrice - foundDiscountPrice.value;
    }

    return price;
  }

  @Watch('activePlanTypes', { immediate: true })
  setIntervalCount() {
    if (!this.activePlanTypes) return;

    this.intervalCount = this.activePlanTypes;
  }

  @Watch('intervalCount')
  setCurrentPlans() {
    if (!this.plans?.length) return;

    this.currentPlans = this.plans.filter((plan: Plan) => plan.planIntervalCount === this.intervalCount && !plan.planHighlight);
  }

  getPixPrice(plan: Plan) {
    if (!plan || !plan.discounts.length) return 0;

    return getPriceDiscount(TYPE_DISCOUNT.PIX, plan);
  }

  handleClickProduct(id: number) {
    this.$router.push({
      name: 'Payment',
      params: {
        feature: 'plano',
        productId: String(id),
      },
    });
  }

  handleClickProductKnowMore(id: number) {
    this.$router.push({
      name: 'Descriptive',
      params: {
        feature: 'planos',
        productId: String(id),
      },
    });
  }
}
