












































































































































import {
  Component, Vue, Prop, Watch,
} from 'vue-property-decorator';

import Cart from '@/assets/icons/Cart.vue';
import PlusPlan from '@/assets/icons/PlusPlan.vue';
import CheckPlanIcon from '@/assets/icons/checkPlan.vue';
import NotIncluded from '@/assets/icons/NotIncluded.vue';

import FeedbackUser from '@/components/FeedbackUser/index.vue';

import Highlight from '../Highlight/index.vue';
import CardPlanLoading from '../CardPlanLoading/CardPlanLoading.vue';
import FreeShipping from '../FreeShipping/FreeShipping.vue';

import { InstanceAmplitude } from '@/share/Util//Metrics/Metrics';
import ActiveModal from '@/share/Util/ActiveModal';

import { LIST_RECURRENCE } from '../CardPlan/constants';

import PaymentService from '@/services/Payment/PaymentService';

import { Description } from '../Descriptions/interface';

@Component({
  components: {
    Cart,
    PlusPlan,
    CheckPlanIcon,
    NotIncluded,
    Highlight,
    FeedbackUser,
    CardPlanLoading,
    FreeShipping,
  },
  filters: {
    formatRecurrence(value: number) {
      return LIST_RECURRENCE[value].toLocaleUpperCase();
    },
    formatInstallments(value: number) {
      return value === 1 ? '' : `${value}x de `;
    },
  },
})
export default class CardPlanBanner extends Vue {
  @Prop({ required: true }) name!: string;
  @Prop({ default: null }) priceWithoutDiscount!: string;
  @Prop({ required: true }) borderColor!: string;
  @Prop({ required: true }) id!: number;
  @Prop({ default: null }) installments!: number;
  @Prop({ required: true }) price!: number;
  @Prop({ default: null }) recurrence!: number;
  @Prop({ default: null }) pricePix!: number;
  @Prop({ default: true }) isBackground!: boolean;
  @Prop() image!: string;
  @Prop({ default: '' }) subtitle!: string;

  private isLoading = false;

  private descriptions: Description[] = [];

  private setModal = new ActiveModal();
  private PaymentService = new PaymentService();

  @Watch('id', {
    immediate: true,
  })
  async getDescriptions() {
    try {
      this.isLoading = true;

      if (!this.id) return;

      const descriptions = await this.PaymentService.getDescriptions(this.id);

      if (descriptions && descriptions.length) {
        const newDescriptions = this.filterDescriptionsWithContent(descriptions);

        this.descriptions = this.sortDescriptions(newDescriptions);
      }

    } catch (error) {
      this.$store.dispatch('Toast/setToast', {
        text: 'Erro ao carregar a descrição do plano.',
        status: 'error',
      });

      console.error(error);
    } finally {
      this.isLoading = false;
    }
  }

  filterDescriptionsWithContent(descriptions: Description[]) {
    if (!descriptions.length) return [];

    return descriptions.filter((description: Description) => description?.content && description.content.length);
  }

  sortDescriptions(listDescriptions: Description[]) {
    const descriptions = listDescriptions.sort((a, b) => a.order - b.order);

    return descriptions;
  }

  handleClickBuyProduct() {
    this.$emit('button-buy-product', this.id);
  }

  handleClickButtonKnowMore(clickLocation: string) {
    this.setTrackAmplitude(clickLocation);

    this.$store.commit('setActiveDescriptiveModal', {
      id: this.id,
      title: this.name,
    });

    setTimeout(() => {
      this.setModal.activeModal('ModalDescriptionsPlan');
    }, 100);
  }

  setTrackAmplitude(clickLocation: string) {
    InstanceAmplitude.setTrack({
      eventName: 'component_click',
      additionalAttrs: {
        data: {
          component: 'CardPlan',
          local: clickLocation,
          productName: this.name,
        },
      },
    });
  }
}
