


































import {
  Component, Vue,
} from 'vue-property-decorator';

import PageHeader from '@/components/PageHeader/PageHeader.vue';
import Footer from '@/components/Footer/Footer.vue';
import PlanTypes from './components/PlanTypes/index.vue';
import Banner from './components/Banner/Banner.vue';
import ContainerPlans from './components/ContainerPlans/ContainerPlans.vue';
import ModalDescriptionsPlan from './components/ModalDescriptionsPlan/ModalDescriptionsPlan.vue';

@Component({
  components: {
    PageHeader,
    Footer,
    Banner,
    PlanTypes,
    ContainerPlans,
    ModalDescriptionsPlan,
  },
})
export default class Shopping extends Vue {
  mounted() {
    this.setBreadCrumbs();
  }

  get isMobile() {
    return this.$responsive.isMobile;
  }

  get planIdSelected() {
    return this.$store.getters?.activeDescriptiveModal?.id;
  }

  get planTitleSelected() {
    return this.$store.getters?.activeDescriptiveModal?.title;
  }

  setBreadCrumbs() {
    this.$breadcrumb.set([
      { title: 'Loja', to: null },
    ]);
  }
}
