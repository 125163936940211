







import {
  Component, Vue,
} from 'vue-property-decorator';

import DeliveryIcon from '@/assets/icons/Delivery.vue';

@Component({
  components: {
    DeliveryIcon,
  },
})
export default class FreeShipping extends Vue {
}
